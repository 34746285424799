import React from 'react';
import './Header.css'; // Import Header-specific CSS
import Logo from './LogoWithText'; // Import the Logo component

function Header() {
  return (
    <header className="header">
      <div className="logo-container">
        <Logo /> {/* Logo component without text */}
      </div>
      <span className="vertical-text">KARNOWSKI DESIGN</span> {/* Text separated from logo for better control */}
      <div className="heading-wrapper">
        <h1>Effective communication Design...</h1>
      </div>
      <div className="intro-wrapper">
        <p>
          Effective communication is key to reaching your audience and delivering a message that resonates.
          With over 20 years of experience in communication design, I specialise in crafting smart,
          visually compelling, and impactful design solutions across digital media and print.
        </p>
      </div>
    </header>
  );
}

export default Header;
