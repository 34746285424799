import React, { useEffect } from 'react';
import './Gallery.css'; // Import the specific CSS for the gallery section
import austemVideo from '../assets/gallery/austem.mp4';
import lisaroet from '../assets/gallery/lisaroet-brochure.jpg';
import branding from '../assets/gallery/branding.jpg';
import next from '../assets/gallery/next.jpg';
import annualReport from '../assets/gallery/annual-report.jpg';
import adltad from '../assets/gallery/adlt-ad.jpg';
import sunbatherMag from '../assets/gallery/sunbather-mag.jpg';
import vanSignage from '../assets/gallery/van-signage.jpg';

const Gallery = () => {
  useEffect(() => {
    const galleryItems = document.querySelectorAll('.gallery-item');
    galleryItems.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('wipe-in');
      }, index * 300); // Stagger the wipe effect for each item
    });
  }, []);

  return (
    <section className="gallery section-padding">
      {/* Row 3 - Three equal thirds */}
      <div className="gallery-grid">
        <div className="gallery-item third">
          <img src={adltad} alt="adlt Magazine ad" />
        </div>
        <div className="gallery-item third">
          <img src={annualReport} alt="Annual Report" />
        </div>
        <div className="gallery-item third">
          <img src={vanSignage} alt="Van Signage" />
        </div>

        {/* Row 2 - Two-thirds and one-third */}
        <div className="gallery-item two-thirds">
          <video src={austemVideo} autoPlay loop muted playsInline />
        </div>
        <div className="gallery-item one-third style-contain">
          <img src={branding} alt="branding.jpg" />
        </div>

        {/* Row 1 - Two equal halves */}
        <div className="gallery-item half">
          <img src={next} alt="Case Study" />
        </div>
        <div className="gallery-item half">
          <img src={lisaroet} alt="Digital Board" />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
