import React, { useState } from 'react';
import './Contact.css'; // Import the specific CSS for the contact section
import adrianImage from '../assets/images/adrian.jpg'; // Adjust path if needed

function Contact() {
  // State to manage form input values
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    message: '',
    honeypot: '', // Honeypot field to trap bots
  });

  // State for submission status messages
  const [status, setStatus] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to decode email address
  const decodeEmail = (encoded) => {
    return atob(encoded);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Honeypot check - If honeypot is filled, abort submission
    if (formData.honeypot) {
      setStatus('Submission blocked due to suspected spam.');
      return;
    }

    setStatus('Sending...');

    try {
      // Send form data to Web3Forms endpoint
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_key: '64f59c37-435b-4925-b7d4-c1c053403af2', // Use your actual Web3Forms access key
          subject: 'New Contact Form Submission',
          from_name: `${formData.name} ${formData.surname}`,
          from_email: formData.email,
          message: formData.message,
          // Add your recipient email using obfuscation
          to_email: decodeEmail('aW5mb0BhZHJpYW5rYXJub3dza2kuY29t'),
        }),
      });

      if (response.ok) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', surname: '', email: '', message: '', honeypot: '' }); // Clear the form
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setStatus('There was an error submitting the form. Please try again.');
    }
  };

  return (
    <section className="contact-section section-padding">
      <div className="contact-container flex-column align-center gap-lg">
        {/* Left Column: Image and Info */}
        <div className="contact-left flex-column align-center mb-20">
          <img className="contact-image rounded-full" src={adrianImage} alt="Adrian" />
          <div className="contact-info text-center">
            <h3>Let's Chat</h3>
            <p>Adrian 0419 857 570</p>
          </div>
        </div>
        {/* Right Column: Form */}
        <div className="contact-form-container max-width-md">
          <form className="form flex-column" onSubmit={handleSubmit}>
            {/* Honeypot field */}
            <input
              type="text"
              name="honeypot"
              value={formData.honeypot}
              onChange={handleChange}
              style={{ display: 'none' }}
              tabIndex="-1"
              autoComplete="off"
            />

            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-input rounded-md"
              required
            />

            <label htmlFor="surname">Surname</label>
            <input
              type="text"
              id="surname"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              className="form-input rounded-md"
              required
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-input rounded-md"
              required
            />

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="form-input rounded-md"
              required
            ></textarea>

            <button type="submit" className="button mt-20">SEND</button>
            <div className="form-status">{status}</div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
