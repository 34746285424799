import React from 'react';
import './App.css'; // Only import the global utility CSS here
import Header from './components/Header';
import MediumSection from './components/MediumSection';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import { HelmetProvider, Helmet } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Adrian Karnowski Design</title>
        <meta
          name="description"
          content="Effective communication is key to reaching your audience. With over 20 years of experience, I create impactful, smart, and visually compelling design solutions."
        />
      </Helmet>
      <div className="App">
        <Header />
        <MediumSection />
        <Gallery />
        <Contact />
      </div>
    </HelmetProvider>
  );
}

export default App;
