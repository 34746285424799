import { useState, useEffect } from 'react';

const ScrollUpComponent = ({ targetId, maxMove = 200, scrollFactor = 0.2 }) => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    const yOffset = window.pageYOffset;
    setOffsetY(yOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      // Apply negative translateY to move the element up as you scroll down
      targetElement.style.transform = `translateY(${-Math.min(maxMove, offsetY * scrollFactor)}px)`;
    }
  }, [offsetY, targetId, maxMove, scrollFactor]);

  return null; // No need to return any JSX, the effect is applied directly to the element with the given ID
};

export default ScrollUpComponent;
