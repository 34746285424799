import React from 'react';
import { motion } from 'framer-motion';

const Logo = () => {
  return (
    <motion.svg
      className="ak-logo" // Add the CSS class here
      width="95"
      height="116"
      viewBox="0 0 95 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{ fill: "#443D3D" }}
      animate={{ fill: ["#443D3D", "#FF5733", "#443D3D"] }}
      transition={{
        duration: 3,
        repeat: Infinity,
        repeatType: "mirror",
      }}
    >
      <motion.g clipPath="url(#clip0_6_84)">
        <motion.path
          d="M71.7031 57.9966L95 34.7993V0L36.7646 57.9966L95 116V81.2007L71.7031 57.9966Z"
          fill="#443D3D"
          animate={{ fill: ["#443D3D", "#443D3D", "#443D3D"] }}
          transition={{
            duration: 6,
            repeat: Infinity,
            repeatType: "mirror",
          }}
        />
        <motion.path
          d="M0 57.9966L8.23831 66.2021L17.0577 57.4178V74.9863L29.1142 87.0017V28.9983L0 57.9966Z"
          fill="#443D3D"
          animate={{ fill: ["#CD9F69", "#8A699A", "#69CDC1"] }}
          transition={{
            duration: 6,
            repeat: Infinity,
            repeatType: "mirror",
          }}
        />
      </motion.g>
      <defs>
        <clipPath id="clip0_6_84">
          <rect width="95" height="116" fill="white" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};

export default Logo;
