import React from 'react';
import './MediumSection.css'; // Import the specific CSS for the medium section
import ScrollUpComponent from './ScrollUpComponent';

function MediumSection() {
  return (
    <section id="mediumSection" className="medium">
      <div className="services">
        <h2>medium...</h2>
        <div className="service-columns">
          <div className="design-print">
            <span className="vertical-title">design & print</span>
            <ul className="medium-list"> {/* Added class "medium-list" */}
              <li>catalogues</li>
              <li>brochures</li>
              <li>branding material</li>
              <li>signage</li>
              <li>exhibition & display</li>
              <li>advertisements</li>
              <li>merchandise</li>
              <li>packaging</li>
              <li>Photography</li>
            </ul>
          </div>
          <div className="digital-services">
            <span className="vertical-title">digital applications</span>
            <ul className="medium-list"> {/* Added class "medium-list" */}
              <li>websites (static & wordpress)</li>
              <li>e-commerce stores</li>
              <li>website maintenance</li>
              <li>seo services</li>
              <li>edm design services</li>
              <li>social media advertising</li>
              <li>banner advertising</li>
            </ul>
          </div>
        </div>
      </div>
      <ScrollUpComponent targetId="mediumSection" maxMove={200} scrollFactor={0.3} />
    </section>
  );
}

export default MediumSection;
